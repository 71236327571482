<template>
  <div class="grid">
    <div class="speed-filters">
      <div class="speed-filters-block">
        <div class="speed-filters-items">
          <template v-for="tab in listTabs" :key="tab.id">
            <div
              class="speed-filters-item"
              v-if="tab.isVisible"
              :class="{ active: tab.isActive }"
              @click="$emit('setTab', tab.id)"
            >
              {{ tab.name }}
              <div v-if="tab.count" class="count">{{ tab.count }}</div>
            </div>
          </template>
        </div>

        <div class="speed-filters-buttons">
          <div
            v-if="isVisibleSelectAllRows"
            class="speed-filters-button"
            @click="selectAllRows(true)"
          >
            <div class="label link">Select All {{ countRows }} rows</div>
          </div>
          <div
            v-if="optionsModel.isSelectable && selectedRowsCount"
            class="speed-filters-button mr-25"
          >
            <div class="label">
              Selected
              {{
                selectedRowsCount +
                ` ${selectedRowsCount > 1 ? ' rows' : ' row'}`
              }}
            </div>
          </div>
          <div
            v-if="optionsModel.isSetColumns"
            class="speed-filters-button"
            @click="
              optionsModel.isShowAllColumns = !optionsModel.isShowAllColumns
            "
          >
            {{ optionsModel.isShowAllColumns ? 'All columns' : 'My  columns' }}
          </div>
          <div
            v-if="optionsModel.isSetColumns"
            class="speed-filters-button"
            @click="showSetColumns"
          >
            <div class="icon columns" v-html="Columns"></div>
            <div class="text">Set columns</div>
          </div>

          <div
            v-if="optionsModel.isDelete && optionsModel.deleteButtonText"
            class="speed-filters-button"
            @click="deleteSelectedRows"
          >
            <div class="icon delete" v-html="Delete"></div>
            <div class="text">{{ optionsModel.deleteButtonText }}</div>
          </div>

          <div
            v-if="optionsModel.isCopyLink"
            class="speed-filters-button"
            @click="exportSelectedCopyLink"
          >
            <div class="icon download" v-html="Link"></div>
            <div class="text">{{ 'Export Link' }}</div>
          </div>

          <div
            v-if="optionsModel.isExportToCSV"
            class="speed-filters-button"
            @click="exportSelected"
          >
            <div class="icon download" v-html="Download"></div>
            <div class="text">{{ optionsModel.exportButtonText }}</div>
          </div>
          <div
            v-if="optionsModel.isAdd"
            class="speed-filters-button"
            @click="addRow"
          >
            <div class="icon add" v-html="PlusNormal"></div>
            <div class="text">{{ optionsModel.addButtonText }}</div>
          </div>
          <div
            v-if="optionsModel.isImport"
            class="speed-filters-button"
            @click="importFromFile"
          >
            <input
              type="file"
              id="upload-file"
              style="display: none"
              @change="uploadFile"
              ref="file"
            />
            <div class="icon import" v-html="Import"></div>
            <div class="text">{{ optionsModel.importButtonText }}</div>
          </div>
          <div
            v-if="optionsModel.isFilters"
            class="speed-filters-button"
            @click="showFilters"
          >
            <div class="icon download" v-html="Filter"></div>
            <div class="text">{{ 'Filters' }}</div>
          </div>

          <template
            v-for="button in optionsModel.titleTableButtons"
            :key="button.id"
          >
            <div
              v-if="button.isVisible"
              class="speed-filters-button"
              :class="button.cssClass"
              @click="clickTitleButton(button)"
            >
              <input
                v-if="button.isFile"
                :id="'gridfile' + button.id"
                type="file"
                style="display: none"
                @change="onChangeFile(button)"
              />
              <div
                class="icon"
                :class="button.imageClass"
                v-html="button.image"
              ></div>
              <div class="text">{{ button.text }}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="separator"></div>
    </div>
    <div>
      <div
        class="scrolable-block container js-grid-scrolable-block"
        ref="table"
      >
        <table>
          <thead>
            <tr>
              <td v-if="optionsModel.isSelectable" class="checkbox">
                <div
                  v-if="optionsModel.isMultiselect"
                  class="checkbox-header"
                  :class="{ checked: checkHeader === 1 }"
                  v-html="getCheckHeader"
                  @click="selectAllRows"
                ></div>
              </td>
              <td v-if="optionsModel.isEditable || optionsModel.isDelete"></td>
              <template
                v-for="column in columsModel.filter(
                  (colFiltered) => !colFiltered.isHide
                )"
                :key="column.key"
              >
                <td :data-key="column.key">
                  <div
                    class="header-cell"
                    @click="setSort(column)"
                    :style="setWidthColumn(column)"
                  >
                    <div>{{ column.text }}</div>
                    <div class="icon" v-if="optionsModel.isSortable">
                      <div
                        v-html="
                          !column.sortType || column.sortType === 'asc'
                            ? HeadArrUp
                            : ''
                        "
                      ></div>
                      <div
                        v-html="
                          !column.sortType || column.sortType === 'desc'
                            ? HeadArrDown
                            : ''
                        "
                      ></div>
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </thead>
          <tbody>
            <component
              v-if="isActiveAddNewRow"
              :is="newRowComponent"
              :columns="columsModel"
              @cancel="isActiveAddNewRow = false"
              :setWidthColumn="setWidthColumn"
              @save="saveNewRow"
            ></component>
            <template v-if="rowsToRender.length">
              <template v-for="row in rowsToRender" :key="row.id">
                <tr
                  v-if="editRowId !== row.id"
                  :class="{ collapsed: colapsedRows.includes(row.id) }"
                >
                  <td v-if="optionsModel.isSelectable" class="checkbox">
                    <div
                      v-if="isSelectedRow(row.id)"
                      class="checkbox-row checked"
                      v-html="CheckboxCheckedRow"
                      @click="selectRow(row.id)"
                    ></div>
                    <div
                      v-else
                      class="checkbox-row"
                      v-html="Checkbox"
                      @click="selectRow(row.id)"
                    ></div>
                  </td>
                  <td v-if="optionsModel.isEditable || optionsModel.isDelete">
                    <div
                      v-if="isShow(optionsModel.isEditable, row)"
                      class="icon-row edit"
                      v-html="Edit"
                      @click="editRow(row)"
                    ></div>
                    <div
                      v-if="isShow(optionsModel.isDelete, row)"
                      class="icon-row delete"
                      @click="deleteRow(row)"
                      v-html="Delete"
                    ></div>
                  </td>

                  <td
                    v-for="column in columsModel.filter(
                      (colFiltered) => !colFiltered.isHide
                    )"
                    :key="column.key"
                    :class="{
                      [column.cssClass]: column.cssClass,
                      includes: isContainsString(row[column.key]),
                    }"
                  >
                    <div
                      class="table-cell"
                      :style="setWidthColumn(column)"
                      v-html="
                        column.formatedValue({
                          value: row[column.key],
                          row: row,
                          rows: rowsModel,
                          options: optionsModel,
                        })
                      "
                    ></div>
                  </td>
                </tr>
                <component
                  v-else
                  :is="newRowComponent"
                  :columns="columsModel"
                  @cancel="editRowId = null"
                  @save="saveRow"
                  :rowData="row"
                  :setWidthColumn="setWidthColumn"
                ></component>
              </template>
            </template>
            <tr class="text-center" v-else>
              <td :colspan="colspan">
                No results found. Try using a different search term.
              </td>
            </tr>
          </tbody>
          <tfoot v-if="!optionsModel.isHideFooter">
            <tr>
              <td :colspan="colspan">
                <div>
                  <component
                    v-if="optionsModel.customFooterComponent"
                    :is="optionsModel.customFooterComponent.component"
                    :options="optionsModel"
                  ></component>
                  <div class="rows-per-page" @click="isOpenRowsPerPage = true">
                    Rows per page <b>{{ per_page }}</b>

                    <ul
                      v-if="isOpenRowsPerPage"
                      v-click-outside="onCloseListIsOpenRowsPerPage"
                    >
                      <li
                        v-for="item in [10, 20, 50, 100, 200, 'all']"
                        :key="item"
                        :class="{ selected: item === per_page }"
                        @click.stop="selectPerPage(item)"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                  <div class="curent-rows">
                    {{ currentRowsMin }}-{{ currentRowsMax }}
                  </div>
                  <div class="total-rows">of {{ countRows }}</div>
                  <div
                    class="icon"
                    @click="prevPage"
                    v-html="NavBack"
                    :class="{ disabled: page === 1 }"
                  ></div>
                  <div class="icon" @click="nextPage" v-html="NavForward"></div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <set-columns
      v-if="isVisibleSetColumns"
      v-model="isVisibleSetColumns"
      :columns="columns"
      @setColumns="setColumns"
    />

    <alert-yes-no
      v-if="isVisibleConfirmAlert"
      title="Confirm"
      :text="contentDeleteInfo"
      :alertResult="alertConfirmDelete"
      :hideYes="optionsModel.deleteModalOptions.hideYes"
      :hideNo="optionsModel.deleteModalOptions.hideNo"
    />

    <alert-yes-no
      v-if="isVisibleConfirmDeleteSelectedRowsAlert"
      title="Confirm"
      :text="contentDeleteInfo"
      :alertResult="alertConfirmDeleteSelected"
      :hideYes="optionsModel.deleteModalOptions.hideYes"
      :hideNo="optionsModel.deleteModalOptions.hideNo"
    />
  </div>
</template>

<style lang="scss">
.container {
  overflow-x: auto;
  height: 100%;
}

.includes {
  color: #fe7248 !important;
  font-weight: 500 !important;
}

.label {
  font-weight: 400;
  color: #616161;

  &.link {
    color: #fe7248;
  }
}

.grid {
  .long-list {
    display: none;
  }

  .short-list {
    display: block;
  }
  .collapsed {
    .long-list {
      display: block;
    }

    .short-list {
      display: none;
    }
  }
}
</style>

<script>
import vClickOutside from 'click-outside-vue3'
import {
  PlusNormal,
  Download,
  NavBack,
  NavForward,
  CaretDown,
  CaretUp,
  Checkbox,
  CheckboxChecked,
  CheckboxCheckedThird,
  CheckboxCheckedRow,
  HeadArrDown,
  HeadArrUp,
  Edit,
  Delete,
  Columns,
  Import,
  Filter,
  Link,
} from '@/utils/icons'
import SetColumns from '@/components/SetColumns.vue'
import AlertYesNo from '@/components/AlertYesNo.vue'

export default {
  name: 'Grid',
  emits: [
    'update:modelValue',
    'update:columns',
    'update:selectedRows',
    'deleteRow',
    'nextPage',
    'prevPage',
    'saveNewRow',
    'saveRow',
    'setPerPage',
    'sortRows',
    'setTab',
    'deleteSelected',
    'exportSelected',
    'exportSelectedCopyLink',
    'changedColumnsParams',
    'showAllColumns',
    'importFile',
    'showFilters',
  ],
  components: { SetColumns, AlertYesNo },
  directives: { clickOutside: vClickOutside.directive },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Object,
      default: () => ({}),
    },
    selectedRows: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    page: { default: 1 },
    per_page: { default: 20 },
    newRowComponent: null,
    isHideNewRowComponent: { default: false },
    countRows: { default: 0 },
    search_text: { default: null },
    listTabs: { default: [] },
  },

  data() {
    return {
      contentDeleteInfo: '',
      PlusNormal,
      Download,
      NavBack,
      NavForward,
      CaretDown,
      CaretUp,
      Checkbox,
      CheckboxChecked,
      CheckboxCheckedThird,
      CheckboxCheckedRow,
      HeadArrDown,
      HeadArrUp,
      Edit,
      Delete,
      Columns,
      Import,
      Filter,
      Link,
      checkHeader: 0,
      checkRow: false,
      isVisibleSetColumns: false,
      colapsedRows: [],
      isActiveAddNewRow: false,
      editRowId: null,
      isVisibleConfirmAlert: false,
      rowToDelete: null,
      isOpenRowsPerPage: false,
      isVisibleConfirmDeleteSelectedRowsAlert: false,
      isSelectedAllRows: false,
      rowsToRender: [],
      lockAddingPage: false,
    }
  },

  watch: {
    isHideNewRowComponent(newValue) {
      if (newValue) {
        this.isActiveAddNewRow = false
        this.editRowId = null
      }
    },

    rowsModel: {
      handler() {
        this.rowsToRender = []
        this.lockAddingPage = false
        this.setRowsToRender()
      },
      deep: true,
    },
  },

  computed: {
    optionsModel: {
      get() {
        return this.options
      },

      set(value) {
        this.$emit('update:options', value)
      },
    },

    rowsModel: {
      get() {
        return this.modelValue
      },

      set(value) {
        this.$emit('update:modelValue', value)
      },
    },

    columsModel: {
      get() {
        return this.columns
      },

      set(value) {
        this.$emit('update:columns', value)
      },
    },

    selectedRowsModel: {
      get() {
        return this.selectedRows
      },

      set(value) {
        this.$emit('update:selectedRows', value)
      },
    },

    selectedRowsCount() {
      if (this.isSelectedAllRows) return this.countRows
      else return this.selectedRowsModel.length
    },

    currentRowsMin() {
      if (this.per_page === 'all') return 1
      return this.page * this.per_page - this.per_page || 1
    },

    currentRowsMax() {
      if (this.per_page === 'all') return this.countRows

      let max = this.page * this.per_page
      return this.countRows < max ? this.countRows : max
    },

    getCheckHeader() {
      if (!this.checkHeader) return Checkbox
      else this.checkHeader === 1
      return CheckboxChecked

      /*else// if(this.checkHeader === 2)
                return CheckboxCheckedThird*/
    },

    colspan() {
      let result = this.columns.filter(
        (colFiltered) => !colFiltered.isHide
      ).length
      //if(this.optionsModel.isEditable || this.optionsModel.isDelete) ++result
      ++result
      if (this.optionsModel.isSelectable) ++result

      return result
    },

    isVisibleSelectAllRows() {
      return (
        this.optionsModel.isSelectable &&
        this.selectedRowsCount &&
        !this.isSelectedAllRows &&
        this.selectedRowsCount >= this.rowsModel.length
      )
    },
  },

  mounted() {
    document.addEventListener('click', this.collapseRow)

    this.$nextTick(() => {
      if (!this.$route.path.includes('/link-builder-partner/'))
        this.$refs.table.addEventListener('scroll', this.updateScroll)
    })
  },

  beforeUnmount() {
    document.removeEventListener('click', this.collapseRow)
    this.$refs.table.removeEventListener('scroll', this.updateScroll)
  },

  methods: {
    onChangeFile(button) {
      button.onClick(document.getElementById('gridfile' + button.id))
    },

    clickTitleButton(button) {
      if (button.isFile) {
        document.getElementById('gridfile' + button.id).click()
        return
      }

      button.onClick(this.$refs['gridfile' + button.id])
    },

    showFilters() {
      this.$emit('showFilters')
    },

    setRowsToRender() {
      if (this.lockAddingPage) return
      this.lockAddingPage = true

      if (this.$route.path.includes('/link-builder-partner/')) {
        this.rowsToRender = this.rowsModel
        this.lockAddingPage = false
      }

      if (
        this.rowsModel.length > 100 &&
        this.rowsToRender.length < this.rowsModel.length
      ) {
        const dif = this.rowsModel.length - this.rowsToRender.length
        const endIndex =
          dif >= 100 ? this.rowsToRender.length + 100 : this.rowsModel.length

        this.rowsToRender = [
          ...this.rowsToRender,
          ...this.rowsModel.slice(this.rowsToRender.length, endIndex),
        ]

        this.lockAddingPage = false
      } else if (this.rowsModel.length <= 100 && !this.rowsToRender.length) {
        this.rowsToRender = [...this.rowsToRender, ...this.rowsModel]
        this.lockAddingPage = false
      }
    },

    updateScroll() {
      if (
        this.$refs.table &&
        this.$refs.table.scrollHeight -
          this.$refs.table.scrollTop -
          this.$refs.table.clientHeight <
          100
      ) {
        this.setRowsToRender()
      }
    },

    async uploadFile() {
      this.$emit('importFile', this.$refs.file)
    },

    importFromFile() {
      this.$refs.file.click()
    },

    isShow(globalPermission, row) {
      if ('can_edit' in row) return row.can_edit

      return globalPermission
    },

    collapseRow(e) {
      if (!e.target.classList.contains('js-colapse')) return

      const rowId = Number(e.target.getAttribute('data-id'))
      let index = this.colapsedRows.findIndex((id) => id === rowId)
      if (index === -1) this.colapsedRows.push(rowId)
      else this.colapsedRows.splice(index, 1)
    },

    selectRow(id) {
      this.isSelectedAllRows = false
      this.checkHeader = 0
      const idx = this.selectedRowsModel.findIndex((item) => item === id)
      if (idx > -1) this.selectedRowsModel.splice(idx, 1)
      else this.selectedRowsModel.push(id)

      /*if(this.selectedRowsModel.length === this.rowsModel.length ) this.checkHeader = 1
            else if(this.selectedRowsModel.length < this.rowsModel.length ) this.checkHeader = 2
            else this.checkHeader = 0*/
    },

    isSelectedRow(id) {
      if (this.isSelectedAllRows) return true
      return this.selectedRowsModel.includes(id)
    },

    setWidthColumn(column) {
      let width = column?.width ? column.width : ''
      return `width:${width};max-width:${width}`
    },

    setSort(column) {
      if (!column.sortType) column.sortType = 'asc'
      else if (column.sortType === 'asc') column.sortType = 'desc'
      else column.sortType = null

      this.$emit('sortRows')
    },

    isContainsString(value) {
      if (
        !value ||
        this.search_text?.length < 3 ||
        typeof value === 'object' ||
        !this.search_text
      )
        return false

      /* if(typeof value === 'object' && value?.length) {
                for (const val of value) {
                    if(typeof val === 'object') {
                        for (const key in val) {
                            console.log('s', val[key])

                            return val[key].toLowerCase().includes(this.search_text.toLowerCase())
                        }
                    }
                }
            }
            else */
      return (value + '')
        .toLowerCase()
        .includes(this.search_text?.toLowerCase())
    },

    selectPerPage(value) {
      this.isOpenRowsPerPage = false
      if (value === this.per_page) return

      this.$emit('setPerPage', value)
    },

    onCloseListIsOpenRowsPerPage() {
      this.isOpenRowsPerPage = false
    },

    addRow() {
      if (!this.optionsModel?.isCardEditable) {
        this.editRowId = null
        this.isActiveAddNewRow = !this.isActiveAddNewRow
      } else {
        window.open(
          window.location.origin + '/' + this.optionsModel?.pathToEdit + '/-1',
          '_blank'
        )
      }
    },

    deleteSelected() {
      this.$emit('deleteSelected', this.isSelectedAllRows)
    },

    async deleteRow(row) {
      this.$store.commit('setIsLoading', true)
      this.contentDeleteInfo = await this.options.getDeleteContent([row])
      this.rowToDelete = row.id
      this.isVisibleConfirmAlert = true
      this.$store.commit('setIsLoading', false)
    },

    exportSelectedCopyLink() {
      this.$emit('exportSelectedCopyLink', this.isSelectedAllRows)
    },

    exportSelected() {
      this.$emit('exportSelected', this.isSelectedAllRows)
    },

    alertConfirmDelete(result) {
      if (result) this.$emit('deleteRow', this.rowToDelete)
      this.isVisibleConfirmAlert = false
      this.rowToDelete = null
    },

    alertConfirmDeleteSelected(result) {
      if (result) this.deleteSelected()
      this.isVisibleConfirmDeleteSelectedRowsAlert = false
    },

    saveNewRow(row) {
      this.$emit('saveNewRow', row)
    },

    saveRow(row) {
      this.$emit('saveRow', { ...{ id: this.editRowId }, ...row })
    },

    nextPage() {
      if (this.per_page === 'all' || this.currentRowsMax >= this.countRows)
        return
      this.$emit('nextPage')
    },

    prevPage() {
      if (this.page === 1 || this.per_page === 'all') return
      this.$emit('prevPage')
    },

    editRow(row) {
      if (!this.optionsModel?.isCardEditable) {
        this.isActiveAddNewRow = false
        this.editRowId = row.id
      } else {
        window.open(
          window.location.origin +
            '/' +
            this.optionsModel?.pathToEdit +
            '/' +
            row.id,
          '_blank'
        )
      }
    },

    async deleteSelectedRows() {
      if (!this.selectedRowsModel.length) return

      this.$store.commit('setIsLoading', true)
      let rows = this.rowsModel.filter((item) =>
        this.selectedRowsModel.includes(item.id)
      )
      this.contentDeleteInfo = await this.options.getDeleteContent(rows)
      this.isVisibleConfirmDeleteSelectedRowsAlert = true
      this.$store.commit('setIsLoading', false)
    },

    setColumns(columns) {
      this.$emit('changedColumnsParams', columns)
      this.columsModel = columns
    },

    showSetColumns() {
      this.isVisibleSetColumns = true
    },

    selectAllRows(isAllRows = false) {
      if (isAllRows === true) {
        this.isSelectedAllRows = true
        this.checkHeader = 1
        this.selectedRowsModel = this.rowsModel.map((item) => item.id)
        return
      }

      this.isSelectedAllRows = false

      if (this.checkHeader === 1) {
        this.checkHeader = 0
        this.selectedRowsModel = []
      } else {
        this.checkHeader = 1
        this.selectedRowsModel = this.rowsModel.map((item) => item.id)
      }
    },
  },
}
</script>
